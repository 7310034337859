import { AppDesignDTO } from './app-design-DTO';

export class ApplicationDTO {
  /**
   * application id
   */
  id: Number;

  /**
   * appName
   */
  appName: string;

  /**
   * appNameShort
   */
  appNameShort: string;

  /**
   * supported Language Translation
   */
  supportedLanguage: Array<String>;
  /**
   * application design
   */
  appDesigns: Array<AppDesignDTO>;

  /**
   * appId
   */
  appId: string;

  appUrl: string;
  appHomepageUrl: string;
  userPoolId: string;
  occupancyIndicateRate: number;
  isEdit: boolean;

  constructor(
    id?: Number,
    appId?: string,
    appName?: string,
    appNameShort?: string,
    supportedLanguage: Array<String> = [],
    appUrl?: string,
    appHomepageUrl?: string,
    userPoolId?: string,
    appDesigns?: Array<AppDesignDTO>,
    occupancyIndicateRate?: number
  ) {
    this.id = id;
    this.appId = appId;
    this.appName = appName;
    this.appNameShort = appNameShort;
    this.supportedLanguage = supportedLanguage;
    this.appUrl = appUrl;
    this.appHomepageUrl = appHomepageUrl;
    this.userPoolId = userPoolId;
    this.appDesigns = appDesigns;
    this.occupancyIndicateRate = occupancyIndicateRate;
  }
}
