import { TicketDetail } from './ticket-detail';

/**
 * Entity class for representing a ticket
 */
export class Ticket {
  id: number; // Identifier for the ticket entity
  ticketId: number; // Identifier for the ticket (from TicketDetail)
  ticketType: string; // Type of the ticket (from TicketDetail)
  ticketName: any; // Full name of the ticket in different anys (from TicketDetail)
  companyId: number;
  companyName: any;
  operatorId: number;
  operatorName: any;
  appId: string;
  appName: any;
  priceAdult: number;
  priceCustom1: number;
  usingPeriod: number;
  usingPeriodType: string;
  updateScheduleAt: string;
  inUse: any;
  isReserve: boolean;

  /**
   * Details about the ticket
   */
  ticketDetail: TicketDetail;
  ticketDetailUpdate: TicketDetail;

  /**
   * Flag indicating if the ticket is checked
   */
  isChecked: boolean;

  /**
   * isChooseDetailUpdate
   */
  isChooseDetailUpdate: boolean;
  isInUseAdult: boolean;
  isInUseCustom1: boolean;
  isInUseCustom2: boolean;
  isInUseCustom3: boolean;
  priceLabelCustom1: string;
  priceLabelCustom2: string;
  priceLabelCustom3: string;
  referencePriceAdult: number;
  referencePriceCustom1: number;
  referencePriceCustom2: number;
  referencePriceCustom3: number;
  currencyUnit: string;
}

// Interface describing pricing details for a ticket
interface TicketPrice {
  adult: number; // Price for an adult ticket
  custom1: number; // Price for custom option 1
  custom2: number; // Price for custom option 2
  custom3: number; // Price for custom option 3
}

// Interface representing language structure
interface Language {
  ja: string; // Japanese language representation
  en: string; // English language representation
}
