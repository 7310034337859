export class StockDetail {
  stockPartitionId: number;
  ticketId: number;
  ticketName: MultiLanguage;
  tripId: string;
  name: MultiLanguage;
  reservationClassId: string;
  reservationClassName: MultiLanguage;
  stockPartitionDescription: MultiLanguage;
  stockId: number;
  reserveOn: string;
  is29hNotation: false;
  reservationPriceId: number;
  dynamicPriceType: string;
  reservationPriceDescription: MultiLanguage;
  currentReservationCount: 100;
  maxReservationCount: 999;
}

export interface MultiLanguage {
  ja: string;
  en: string;
}
