export class ReservationDetail {
  reservationId: string;
  reservationVerifyCode: string;
  userId: string;
  reservationStatus: string;
  representativeName: string;
  phoneNumber: string;
  email: string;
  custom1Name: MultiLanguage;
  custom2Name: MultiLanguage;
  custom3Name: MultiLanguage;
  reservationNumberAdult: number;
  reservationNumberCustom1: number;
  reservationNumberCustom2: number;
  reservationNumberCustom3: number;
  createdBy: string;
  updatedBy: string;
  priceAdult: number;
  priceCustom1: number;
  priceCustom2: number;
  priceCustom3: number;
  totalPrice: number;
  reservationPriceDescription: MultiLanguage;
  reservationClassId: string;
  dynamicPriceType: string;
  displayStartAt: string;
  displayEndAt: string;
  reserveOn: string;
  tripId: string;
  name: MultiLanguage;
  stockPartitionDescription: MultiLanguage;
  reservationClassName: MultiLanguage;
  orderId: string;
  saleTicketId: number;
  saleTicketName: MultiLanguage;
  ticketId: number;
  ticketName: MultiLanguage;
  inUseOperation: number;
  orderUsageStatus: string;
  languages: string;
  remarkAnswer: string;
  updatedAt: string;
}

export interface MultiLanguage {
  ja: string;
  en: string;
}
